import React, { FC, memo } from 'react';
import Capital from "@layouts/Capital";
import * as S from "./Nft.styled";
import SVG from "@ui/SVG/SVG";
import { useSelector } from "react-redux";
import { selectCategories } from "@src/redux/slices/itemCategorySlice";
import { selectItems } from "@src/redux/slices/itemSlice";
import { selectCurrentItems } from "@src/redux/slices/playerSlice";

const Nft: FC = () => {
  const categories = useSelector(selectCategories);
  const items = useSelector(selectItems);
  const currentItems = useSelector(selectCurrentItems);
  const mapState = new Map(JSON.parse(currentItems));

  return (
    <Capital>
      <S.Wrapper>
        <S.Title>My NFT</S.Title>
        <S.Constructor>
          <S.Body>
            {categories.map(category => {
              const currentItem = items.find(item => item.id === mapState.get(category.id));
              if (currentItem) {
                return currentItem.layers.map(layer => (
                  <img
                    key={`${category.id}-${layer.id}`}
                    src={`https://api.avataria-nft.ru/images/item-layer/${layer.image}`}
                    style={{
                      top: layer.y,
                      left: layer.x,
                      zIndex: layer.z_index || layer.z_index === 0 ? layer.z_index : category.z_index,
                      opacity: 0,
                    }}
                    onLoad={event => {
                      const image = event.target as HTMLImageElement;
                      image.style.opacity = `${1}`;
                    }}
                    alt=""
                  />
                ));
              }
              return null;
            })}
          </S.Body>
        </S.Constructor>
        <S.SubTitle>Базовые атрибуты</S.SubTitle>
        <S.Grid>
          <SVG
            name="atribute"
          />
          <SVG
            name="atribute"
          />
          <SVG
            name="atribute"
          />
          <SVG
            name="atribute"
          />
          <SVG
            name="atribute"
          />
          <SVG
            name="atribute"
          />
        </S.Grid>
        <S.SubTitle>Дополнительные параметры</S.SubTitle>
        <S.Grid>
          <SVG
            name="atribute"
          />
          <SVG
            name="atribute"
          />
        </S.Grid>
        <S.Button>
          <SVG
            name="lock"
          />
          MINT
        </S.Button>
      </S.Wrapper>
    </Capital>
  )
};

export default memo(Nft);
