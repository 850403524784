import * as React from 'react';
import * as S from './LevelBar.styled';
import {FC, memo} from 'react';
import {ranks} from '@common/string';
import {selectLevel, selectPhotoUrl, selectProgress, selectUsername} from '@src/redux/slices/playerSlice';
import {useSelector} from 'react-redux';

const LevelBar: FC = () => {
    const level = useSelector(selectLevel);
    const progress = useSelector(selectProgress);
    const userName = useSelector(selectUsername);
    const photoUrl = useSelector(selectPhotoUrl);

    return (
        <S.LevelBar>
            <S.Avatar>
                <img src={photoUrl} alt=""/>
            </S.Avatar>
            <S.Content>
                <S.Name>@{userName || 'unknown'}</S.Name>
                <S.Progress>
                    <S.CurrentProgress $width={progress}/>
                    <S.Level>
                        <span>Lvl {level}</span>
                        <span>Lvl {level + 1}</span>
                    </S.Level>
                </S.Progress>
            </S.Content>
        </S.LevelBar>
    );
};

export default memo(LevelBar);
