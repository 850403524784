import * as React from 'react';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import * as S from './Friends.styled';
import { selectReferralLink } from '@src/redux/slices/playerSlice';
import { selectReferrals } from '@src/redux/slices/referralSlice';
import { useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import SVG from '@ui/SVG/SVG';
import Capital from "@layouts/Capital";
import ReferralImg from "@src/images/referral.png";
import { selectPlayers } from "@src/redux/slices/ratingSlice";
import Place1Img from '@src/images/place-1.png';
import Place2Img from '@src/images/place-2.png';
import Place3Img from '@src/images/place-3.png';
import Button from "@ui/Button/Button";
import { ButtonType } from "@src/app/types";

enum Tabs {
  REFERRAL = 'referral',
  WORLD = 'world',
  SQUAD = 'squad'
}

const Friends: FC = () => {
  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const playerReferralLink = useSelector(selectReferralLink);
  const referrals = useSelector(selectReferrals);
  const ratingPlayers = useSelector(selectPlayers);
  const [activeTab, setActiveTab] = useState(Tabs.REFERRAL);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const tabWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setWrapperWidth(tabWrapperRef.current?.offsetWidth || 0);
  }, [tabWrapperRef.current]);

  const shareLink = useCallback(() => {
    impactOccurred('soft');
    const shareText = encodeURIComponent('Играй со мной в AVATARIA!'
      + '\n💸 5k монет в качестве первого подарка'
      + '\n🔥 25k монет, если у тебя есть Telegram Premium');
    const shareUrl = encodeURIComponent(`https://t.me/AvatariaNftBot/start?startapp=${playerReferralLink}`);
    const link = `https://t.me/share/url?url=${shareUrl}&text=${shareText}`;
    Telegram.WebApp.openTelegramLink(link);
  }, [impactOccurred, playerReferralLink]);

  const copyLink = useCallback(() => {
    impactOccurred('soft');
    const link = `https://t.me/AvatariaNftBot/start?startapp=${playerReferralLink}`;

    navigator.clipboard.writeText(link).then(() => {
      notificationOccurred('success');
    }, err => {
      notificationOccurred('error');
      Telegram.WebApp.showAlert(`Ошибка при копировании ссылки: ${err}`);
    });
  }, [impactOccurred, notificationOccurred, playerReferralLink]);

  return (
    <>
      <Capital>
        <S.Banner>
          <S.BannerContent>
            <S.BannerReward>
              <SVG name="coin" width={28} height={28} />
              25 000
            </S.BannerReward>
            <S.BannerText>Ваша текущая награда за одного реферала</S.BannerText>
          </S.BannerContent>
          <S.ButtonWrapper>
            <Button
              text="Пригласить"
              clickCallback={shareLink}
            />
            <Button
              type={ButtonType.BORDER}
              text={<SVG name="copy"/>}
              clickCallback={copyLink}
            />
          </S.ButtonWrapper>
        </S.Banner>
        <S.TabWrapper ref={tabWrapperRef}>
          <S.Tab $isActive={activeTab === Tabs.REFERRAL} onClick={() => setActiveTab(Tabs.REFERRAL)}>
            Рефералы
          </S.Tab>
          <S.Tab $isActive={activeTab === Tabs.WORLD} onClick={() => setActiveTab(Tabs.WORLD)}>
            Весь мир
          </S.Tab>
          <S.Tab disabled>
            <SVG name="lock" customColor="currenColor"/>
            Сквады
          </S.Tab>
        </S.TabWrapper>
        <>
          {activeTab === Tabs.REFERRAL && !referrals.length && (
            <div>У тебя нет друзей :(</div>
          )}
          {activeTab === Tabs.REFERRAL && referrals.map(referral => (
            <S.RatingReferral key={referral.telegram_id}>
              <S.RatingReferralTop>
                <S.Avatar>
                  <img src={referral.photo_url} alt=""/>
                </S.Avatar>
                <S.PlayerContent>
                  <S.PlayerName>@{referral.username || 'unknown'}</S.PlayerName>
                  <S.PlayerLevel>Lvl {referral.level}</S.PlayerLevel>
                </S.PlayerContent>
                <S.RatingReferralBonus>
                  Ваш бонус
                  <S.RatingReferralBonusValue>
                    <SVG name="gem" width={10} height={10}/>
                    +10
                  </S.RatingReferralBonusValue>
                </S.RatingReferralBonus>
              </S.RatingReferralTop>
              <S.RatingReferralLevel>
                <S.RatingReferralLevelValue
                    $isActive={referral.level >= 1}
                    $width={wrapperWidth}
                >
                  1
                </S.RatingReferralLevelValue>
                <S.RatingReferralLevelValue
                    $isActive={referral.level >= 3}
                    $width={wrapperWidth}
                >
                  3
                </S.RatingReferralLevelValue>
                <S.RatingReferralLevelValue
                    $isActive={referral.level >= 6}
                    $width={wrapperWidth}
                >
                  6
                </S.RatingReferralLevelValue>
                <S.RatingReferralLevelValue
                    $isActive={referral.level >= 9}
                    $width={wrapperWidth}
                >
                  9
                </S.RatingReferralLevelValue>
                <S.RatingReferralLevelValue
                    $isActive={referral.level >= 12}
                    $width={wrapperWidth}
                >
                  12
                </S.RatingReferralLevelValue>
                <S.RatingReferralLevelValue
                    $isActive={referral.level >= 15}
                    $width={wrapperWidth}
                >
                  15
                </S.RatingReferralLevelValue>
              </S.RatingReferralLevel>
              <S.RatingReferralGift>
                <S.RatingReferralGiftValue $isActive={referral.level >= 1}>
                  <SVG name="gem" width={10} height={10}/>
                  10
                </S.RatingReferralGiftValue>
                <S.RatingReferralGiftValue $isActive={referral.level >= 3}>
                  <SVG name="gem" width={10} height={10}/>
                  35
                </S.RatingReferralGiftValue>
                <S.RatingReferralGiftValue $isActive={referral.level >= 6}>
                  <SVG name="gem" width={10} height={10}/>
                  75
                </S.RatingReferralGiftValue>
                <S.RatingReferralGiftValue $isActive={referral.level >= 9}>
                  <SVG name="gem" width={10} height={10}/>
                  150
                </S.RatingReferralGiftValue>
                <S.RatingReferralGiftValue $isActive={referral.level >= 12}>
                  <SVG name="gem" width={10} height={10}/>
                  300
                </S.RatingReferralGiftValue>
                <S.RatingReferralGiftValue $isActive={referral.level >= 15}>
                  <SVG name="gem" width={10} height={10}/>
                  600
                </S.RatingReferralGiftValue>
              </S.RatingReferralGift>
            </S.RatingReferral>
          ))}
          {activeTab === Tabs.WORLD && ratingPlayers.map((player, index) => (
            <S.RatingPlayer key={player.telegram_id}>
              <S.Avatar>
                <img src={player.photo_url} alt=""/>
              </S.Avatar>
              <S.PlayerContent>
                <S.PlayerName>@{player.username || 'unknown'}</S.PlayerName>
                <div></div>
              </S.PlayerContent>
              <S.PlayerPlace>
                {index === 0 && <img src={Place1Img} alt=""/>}
                {index === 1 && <img src={Place2Img} alt=""/>}
                {index === 2 && <img src={Place3Img} alt=""/>}
                {index > 2 && `#${index}`}
              </S.PlayerPlace>
            </S.RatingPlayer>
          ))}
        </>
      </Capital>
    </>
  );
};

export default memo(Friends);
