import Main from '@layouts/Main';
import styled from 'styled-components';

export const Loader = styled(Main)`
    align-items: center;
    justify-content: flex-start;
`;

export const Text = styled.div`
    padding-top: 14%;
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
    text-align: center;
    margin-bottom: auto;
`;
