import styled, {css} from "styled-components";
import BgPattern from "@src/images/bg-pattern.svg";

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 50px;
    gap: 10px;
`;

export const Constructor = styled.div`
    height: calc(100vw - 20px - 50px - 10px);
    min-height: 350px;
    border-radius: 10px;
    background-image: url("${BgPattern}");
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

export const Body = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 100%;

    img {
        display: block;
        position: absolute;
        max-height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
`;

export const ColorWrapper = styled.div`
    background-color: #F6F6F6;
    border-radius: 18px;
    padding: 20px 12px;
    max-height: calc(100vw - 20px - 50px - 10px);
    min-height: 350px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`;

type TColorButton = {
    color: string;
}

export const ColorButton = styled.button<TColorButton>`${({color}) => css`
    position: relative;
    background-color: ${color};
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin: 3px;
    
    &:after {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border-radius: 50%;
        border: 1px dashed #04101E;
    }

    & + & {
        margin-top: 11px;
    }
`}`

export const ItemWrapper = styled.div`
    grid-column: 1/-1;
`;

type TCategory = {
    isActive: boolean;
}

export const Category = styled.button<TCategory>`${({isActive}) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background-color: ${isActive ? '#2872EF' : '#F6F6F6'};
    border-radius: 50%;

    img {
        ${!isActive && css`filter: brightness(0.7);`}
    }
`}`;

export const CategoryGrid = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
`;

export const ItemGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px 6px;
    margin-top: 16px;
`;

export const Item = styled.div`
    background-color: #F6F6F6;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 14px;
`;

export const ItemImage = styled.div`
    margin: 25px 0 8px;
    width: 80px;
    height: 80px;
    position: relative;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
    }
`;

export const ItemName = styled.div`
    font-size: 10px;
    line-height: 1.1;
`;

export const ItemType = styled.div`
    font-size: 10px;
    line-height: 1.1;
    color: rgba(255, 255, 255, 0.6);
`;

export const ItemPrice = styled.div`
    width: 100%;
    background-color: #EAEAEA;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    padding: 8px 4px;
    margin-top: 4px;
    border-radius: 0 0 14px 14px;
`;
