import * as S from './DotLoader.styled';
import React, { FC, memo } from 'react';

const DotLoader: FC = () => (
  <S.Wrapper>
    <S.Dot />
    <S.Dot />
    <S.Dot />
    <S.Dot />
  </S.Wrapper>
);

export default memo(DotLoader);
