import * as React from 'react';
import * as S from './PlayerBalance.styled';
import { FC, memo, useCallback, useState } from 'react';
import { selectBalanceCoins } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';
import SVG from '@ui/SVG/SVG';
import { getHumanBalance } from "@common/number";
import Modal from "@ui/Modal/Modal";
import LetterIcon from "@src/images/letter.png";
import Button from "@ui/Button/Button";
import { selectGems } from "@src/redux/slices/gemSlice";

type TProps = {
  className?: string;
}

const PlayerBalance: FC<TProps> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const balance = useSelector(selectBalanceCoins);
  const gems = useSelector(selectGems);

  const closeCallback = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <S.Wrapper>
        <S.Balance className={className}>
          <SVG name="coin" width={20} height={20} />
          <S.BalanceText>
            {getHumanBalance(balance)}
          </S.BalanceText>
          <S.Link to="/income">
            <SVG name="plus"  />
          </S.Link>
        </S.Balance>
        <S.Balance className={className}>
          <SVG name="gem" width={20} height={20} />
          <S.BalanceText>
            0
          </S.BalanceText>
          <S.Button onClick={() => setIsOpen(true)}>
            <SVG name="plus" />
          </S.Button>
        </S.Balance>
        <S.Swap className={className}>
          <SVG name="swap" width={18} height={18} />
          <S.SwapText>
            Swap
          </S.SwapText>
        </S.Swap>
      </S.Wrapper>
      {isOpen ? (
        <Modal
          title="Купить алмазы"
          closeCallback={closeCallback}
          imageSrc={LetterIcon}
        >
          <S.GemModalHeader>
            <S.GemModalHeaderText>Выбери количество</S.GemModalHeaderText>
            <S.GemModalHeaderBalance>
              <S.GemModalHeaderBalanceText>Баланс</S.GemModalHeaderBalanceText>
              <S.GemModalHeaderBalanceValue>
                <SVG name="star" />
                0
              </S.GemModalHeaderBalanceValue>
            </S.GemModalHeaderBalance>
          </S.GemModalHeader>
          <S.GemWrapper>
            {gems.map(gem => (
              <S.GemItem key={gem.id}>
                <S.GemItemCount>
                  <SVG name="gem"/>
                  {gem.count}
                </S.GemItemCount>
                <S.GemItemPrice>
                  <SVG name="star"/>
                  {gem.price}
                </S.GemItemPrice>
              </S.GemItem>
            ))}
          </S.GemWrapper>
          <Button
            text="Купить"
            clickCallback={console.log}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default memo(PlayerBalance);
