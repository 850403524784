import { createGlobalStyle, css } from 'styled-components';
import Background from '@src/images/bg.jpg';

export const GlobalStyles = createGlobalStyle`${() => css`
    *, *::after, *::before {
        box-sizing: border-box;
        user-select: none;
        user-drag: none;
        touch-action: pan-x pan-y;
        -webkit-tap-highlight-color: transparent;
    }
    
    html {
        overflow: hidden;
    }

    html, body, #root {
        margin: 0;
        height: 100%;
        max-height: 100%;
        text-rendering: optimizelegibility;
        -webkit-font-smoothing: antialiased;
    }

    body {
        font-family: 'Roboto', system-ui, sans-serif;
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.1;
        background-color: #FFF;
        color: #04101E;
        color-scheme: light;
        -webkit-overflow-scrolling: touch;
        min-width: 375px;
        overflow-y: scroll;
        max-height: 100%;
        position: fixed;
        inset: 0;
        padding-top: calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top));

        &.modal-open {
            overflow-y: hidden;
        }
    }

    #root {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    a {
        text-decoration: none;
    }

    img {
        max-width: 100%;
        object-fit: contain;
        pointer-events: none;
    }

    p {
        margin: 0;
    }

    button {
        cursor: pointer;
        border: none;
        background: none;
        outline: none;
        padding: 0;
        color: inherit;
    }
`}`;
