import styled from 'styled-components';
import ModalHeaderLeft from '@src/images/modal-header-left.svg'
import ModalHeaderCenter from '@src/images/modal-header-center.svg'
import ModalHeaderRight from '@src/images/modal-header-right.svg'

export const Modal = styled.div`
    position: fixed;
    z-index: 120;
    inset: 0;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    flex-direction: column;
    overflow: auto;
    backdrop-filter: blur(8px);
`;

export const BodyWrapper = styled.div`
    position: relative;
    margin: auto 12px;
    animation: ModalFadeIn 0.3s;
    padding-top: 64px;

    @keyframes ModalFadeIn {
        0% {
            opacity: 0;
            transform: translate(0, 24px);
        }

        100% {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
`;

export const Body = styled.div`
    background-color: #fff;
    border-radius: 0 0 30px 30px;
    padding: 20px;
    text-align: center;
    border: 2px solid #E6E8E9;
    border-top: none;
`;

export const BodyHeader = styled.div`
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 34px;
    left: 0;
    width: 100%;
    height: 30px;
`;

export const BodyHeaderLeft = styled.div`
    width: 30px;
    height: 30px;
    background-image: url("${ModalHeaderLeft}");
`;

export const BodyHeaderFill = styled.div`
    flex: 1 1 auto;
    height: 30px;
    background-color: #fff;
    border-top: 2px solid #E6E8E9;
`;

export const BodyHeaderCenter = styled.div`
    width: 95px;
    height: 30px;
    background-image: url("${ModalHeaderCenter}");
`;

export const BodyHeaderRight = styled.div`
    width: 30px;
    height: 30px;
    background-image: url("${ModalHeaderRight}");
`;


export const Backdrop = styled.button`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const Close = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Icon = styled.div`
    background: #007AFF;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
`;

export const Image = styled.img`
    display: block;
`;

export const Title = styled.div`
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 12px;
`;
