import styled, { css } from 'styled-components';

export const TaskGrid = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1 1 auto;
`;

type TTask = {
  $isDone?: boolean;
}

export const Task = styled.div<TTask>`${({ $isDone = false }) => css`
    border-radius: 20px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #F6F6F6;
`}`;

export const TaskContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
`;

export const TaskName = styled.div`
    font-size: 16px;
  font-weight: 600;
`;

export const TaskDescription = styled.div`
    font-size: 12px;
`;

export const TaskReward = styled.div`
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 2px;
`;

export const TaskButton = styled.div`
    font-size: 12px;
  font-weight: 600;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #2872EF;
    border-radius: 12px;
    padding: 8px 16px;
`;
