import styled, {css} from 'styled-components';
import {Link} from "react-router-dom";
import BackgroundPatter from '@src/images/bg-pattern.svg';

export const TapContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto 0 0;
`;

type TTapButton = {
    $isTaping: boolean;
}

export const Balance = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 44px;
    font-weight: 600;
    line-height: 1;
    gap: 12px;

    svg {
        margin-top: 4px;
    }
`;

export const TapButton = styled.button<TTapButton>`${() => css`
    display: flex;
    transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
    color: #fff;
    width: 100%;
    position: relative;
`}`;

export const Body = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 130%;

    img {
        display: block;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: 0;
    }
`;

export const TapFooter = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 15px;
    left: 0;
    z-index: 50;
    padding: 0 16px;
`;

export const Energy = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background-color: #fff;
    border-radius: 20px;
    position: relative;
    width: 180px;
    gap: 8px;
    margin: 0 auto;

    svg {
        position: relative;
    }
`;

type TTapFooterProgress = {
    $width: number;
}

export const TapFooterProgress = styled.div<TTapFooterProgress>`${({$width}) => css`
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(${$width}% - 4px);
    height: calc(100% - 4px);
    background-image: linear-gradient(270deg, #047CFF 6.24%, #BDDAFA 100%);
    border-radius: 20px;
`}`;

export const EnergyText = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    color: #fff;

    span:nth-child(2) {
        color: #04101E;
        opacity: .25;
    }
`;

export const BoostLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007AFF;
    margin-left: -40px;
`;

export const ButtonBackground = styled.div`
    background-image: url("${BackgroundPatter}");
    background-repeat: repeat;
    width: calc(100% + 32px);
    height: calc(100% - 32px);
    position: absolute;
    bottom: 0;
    left: -16px;
    border-radius: 32px 32px 0 0;
`;

export const Animate = styled.div`
    pointer-events: none;
    user-select: none;

    div {
        position: fixed;
        z-index: 100;
        font-size: 60px;
        font-weight: 700;
        animation: appear 1s;

        @keyframes appear {
            0% {
                opacity: 1;
                transform: translate(0, 0) scale(1);
            }

            100% {
                opacity: 0;
                transform: translate(0, -8rem) scale(0.5);
            }
        }
    }
`;
