import React, { FC, memo, useEffect, useState } from 'react';
import Capital from "@layouts/Capital";
import { useDispatch, useSelector } from "react-redux";
import { selectCategories } from "@src/redux/slices/itemCategorySlice";
import * as S from './Shop.styled';
import { selectItems } from "@src/redux/slices/itemSlice";
import SVG from "@ui/SVG/SVG";
import { selectSkins } from "@src/redux/slices/skinSlice";
import { sendRequest } from "@src/network";
import { selectCurrentItems, setItems } from "@src/redux/slices/playerSlice";

const Shop: FC = () => {
  const dispatch = useDispatch();
  const skins = useSelector(selectSkins);
  const categories = useSelector(selectCategories);
  const items = useSelector(selectItems);
  const currentItems = useSelector(selectCurrentItems);
  const [currentCategory, setCurrentCategory] = useState(categories[0]);
  const [mapState, setMapState] = useState(new Map(JSON.parse(currentItems)));
  const updateMap = (key: number, value: number) => {
    setMapState(map => new Map(map.set(key, value)));
  }
  const removeFromMap = (key: number) => {
    setMapState(map => {
      map.delete(key);
      return new Map(map);
    });
  }

  useEffect(() => {
    const items = JSON.stringify(Array.from(mapState.entries()));
    sendRequest('/player/set-items', {
      method: 'post',
      body: {
        items,
      }
    }).then(() => dispatch(setItems({
      items,
    })))
  }, [mapState]);

  return (
    <Capital hideUserInfo>
      <S.Wrapper>
        <S.Constructor>
          <S.Body>
            {categories.map(category => {
              const currentItem = items.find(item => item.id === mapState.get(category.id));
              if (currentItem) {
                return currentItem.layers.map(layer => (
                  <img
                    key={`${category.id}-${layer.id}`}
                    src={`https://api.avataria-nft.ru/images/item-layer/${layer.image}`}
                    style={{
                      zIndex: layer.z_index || layer.z_index === 0 ? layer.z_index : category.z_index,
                      opacity: 0,
                    }}
                    onLoad={event => {
                      const image = event.target as HTMLImageElement;
                      image.style.opacity = `${1}`;
                    }}
                    alt=""
                  />
                ));
              }
              return null;
            })}
          </S.Body>
        </S.Constructor>
        <S.ColorWrapper>
          {skins.map(skin => (
            <S.ColorButton key={skin.id} color={skin.color} />
          ))}
        </S.ColorWrapper>
        <S.ItemWrapper>
          <S.CategoryGrid>
            {categories.map(category => (
              <S.Category
                key={category.id}
                isActive={currentCategory.id === category.id}
                onClick={() => setCurrentCategory(category)}
              >
                {category.image
                  ? <img src={`https://api.avataria-nft.ru/images/category/${category.image}`} alt=""/>
                  : category.name}
              </S.Category>
            ))}
          </S.CategoryGrid>
          <S.ItemGrid key={currentCategory.id}>
            {!currentCategory.is_required && (
              <S.Item key={`category-${currentCategory.id}`} onClick={() => removeFromMap(currentCategory.id)}>
                <S.ItemImage>
                  <img
                    src={`https://api.avataria-nft.ru/images/category/${currentCategory.image}`}
                    alt=""/>
                </S.ItemImage>
                <S.ItemType>Снять</S.ItemType>
              </S.Item>
            )}
            {items.filter(item => currentCategory.id === item.category_id).map(item => (
              <S.Item key={item.id} onClick={() => updateMap(item.category_id, item.id)}>
                <S.ItemImage>
                  {item.layers.map(layer => (
                      <img
                          src={`https://api.avataria-nft.ru/images/item-layer/${layer.image}`}
                          style={{
                            zIndex: layer.z_index || layer.z_index === 0 ? layer.z_index : currentCategory.z_index,
                          }}
                          alt=""
                      />
                  ))}
                </S.ItemImage>
                <S.ItemName>{item.name}</S.ItemName>
                <S.ItemType>Обычная</S.ItemType>
                <S.ItemPrice>
                  <SVG name="coin" width={12} height={12}/>
                  {item.price}
                </S.ItemPrice>
              </S.Item>
            ))}
          </S.ItemGrid>
        </S.ItemWrapper>
      </S.Wrapper>
    </Capital>
  );
}

export default memo(Shop);
