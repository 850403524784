import { Outlet, useNavigate } from 'react-router-dom';
import { fetchCards, selectCardsLoading } from '@src/redux/slices/cardSlice';
import { fetchCategories, selectCategoriesLoading } from '@src/redux/slices/itemCategorySlice';
import { fetchItems, selectItemsLoading } from '@src/redux/slices/itemSlice';
import { fetchPlayer, passiveEarn, selectPlayerLoading } from '@src/redux/slices/playerSlice';
import { fetchReferrals, selectReferralsLoading } from '@src/redux/slices/referralSlice';
import { fetchTasks, selectTaskLoading } from '@src/redux/slices/taskSlice';
import { useAppDispatch } from '@common/hooks';
import { useSelector } from 'react-redux';
import EarnModal from '@components/EarnModal/EarnModal';
import Loader from '@components/Loader/Loader';
import Navigation from '@components/Navigation/Navigation';
import React, { FC, memo, useEffect, useMemo } from 'react';
import ToastStack from '@components/Toast/ToastStack';
import '@src/i18n/i18n.config';
import { fetchPlayers, selectRatingLoading } from "@src/redux/slices/ratingSlice";
import { fetchGems, selectGemLoading } from "@src/redux/slices/gemSlice";
import { fetchSkins, selectSkinLoading } from "@src/redux/slices/skinSlice";

const App: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const playerLoading = useSelector(selectPlayerLoading);
  const cardsLoading = useSelector(selectCardsLoading);
  const tasksLoading = useSelector(selectTaskLoading);
  const referralsLoading = useSelector(selectReferralsLoading);
  const categoryLoading = useSelector(selectCategoriesLoading);
  const itemLoading = useSelector(selectItemsLoading);
  const ratingLoading = useSelector(selectRatingLoading);
  const gemLoading = useSelector(selectGemLoading);
  const skinLoading = useSelector(selectSkinLoading);

  useEffect(() => {
    const callback = () => navigate('/');
    Telegram.WebApp.BackButton.onClick(callback);

    return () => {
      Telegram.WebApp.BackButton.offClick(callback);
    };
  }, [navigate]);

  useEffect(() => {
    const fetchingDispatch = dispatch(fetchPlayer());
    fetchingDispatch.then(() => {
      dispatch(fetchCards());
      dispatch(fetchTasks());
      dispatch(fetchReferrals());
      dispatch(fetchCategories());
      dispatch(fetchItems());
      dispatch(fetchPlayers());
      dispatch(fetchGems());
      dispatch(fetchSkins());
    });
    return () => fetchingDispatch.abort();
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(passiveEarn());
    }, 1000);

    return () => clearTimeout(intervalId);
  }, [dispatch]);

  const isLoading = useMemo(() => (
    playerLoading
    || cardsLoading
    || tasksLoading
    || referralsLoading
    || categoryLoading
    || itemLoading
    || ratingLoading
    || gemLoading
    || skinLoading
  ), [cardsLoading, categoryLoading, playerLoading, referralsLoading,
    tasksLoading, itemLoading, ratingLoading, gemLoading, skinLoading]);

  return (
    <>
      <ToastStack />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Outlet />
          <Navigation />
          <EarnModal />
        </>
      )}
    </>
  );
};

export default memo(App);
