import { Link as BaseLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Footer = styled.div`
    padding: 12px 24px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    position: sticky;
  bottom: 0;
    z-index: 100;
    
    .modal-open & {
        z-index: 0;
    }
`;

type TLink = {
  $isActive: boolean;
}

export const Link = styled(BaseLink)<TLink>`${({ $isActive }) => css`
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${$isActive ? '#FFF' : '#04101E'};
    background-color: ${$isActive ? '#007AFF' : 'transparent'};
    pointer-events: ${$isActive ? 'none' : 'auto'};
    border-radius: 50%;
    transition: color .2s ease-in-out, background-color .2s ease-in-out;
`}`;
