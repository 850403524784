import * as React from 'react';
import * as S from '@components/Mining/Mining.styled';
import { FC, TouchEvent, memo, useCallback, useRef, useState } from 'react';
import { TPlayer } from '@src/app/types';
import {
  selectAvailableTaps,
  selectCurrentItems,
  selectEarnPerTap, selectHumanBalance,
  selectMaxTaps,
  setPlayer,
  tapEarn
} from '@src/redux/slices/playerSlice';
import { sendRequest } from '@src/network';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import Capital from '@layouts/Capital';
import SVG from '@ui/SVG/SVG';
import { selectCategories } from "@src/redux/slices/itemCategorySlice";
import { selectItems } from "@src/redux/slices/itemSlice";

const Mining: FC = () => {
  const [impactOccurred] = useHapticFeedback();
  const dispatch = useAppDispatch();
  const earnPerTap = useSelector(selectEarnPerTap);
  const availableTaps = useSelector(selectAvailableTaps);
  const maxTaps = useSelector(selectMaxTaps);
  const categories = useSelector(selectCategories);
  const items = useSelector(selectItems);
  const currentItems = useSelector(selectCurrentItems);
  const balance = useSelector(selectHumanBalance);
  const mapState = new Map(JSON.parse(currentItems));
  const [isTaping, setIsTaping] = useState(false);
  const clickCount = useRef<number>(0);
  const clickTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const tapButtonRef = useRef<HTMLButtonElement>(null);
  const animateRef = useRef<HTMLDivElement>(null);

  const onClickCallback = useCallback((event: TouchEvent) => {
    event.preventDefault();
    impactOccurred('soft');

    if (availableTaps > earnPerTap) {
      setIsTaping(false);
      dispatch(tapEarn());
      clickCount.current += 1;

      if (animateRef.current) {
        const animateElement = document.createElement('div');
        animateElement.style.top = `${event.changedTouches[0].clientY}px`;
        animateElement.style.left = `${event.changedTouches[0].clientX}px`;
        animateElement.innerHTML = `+${earnPerTap}`;
        animateRef.current.append(animateElement);

        animateElement.addEventListener('animationend', () => animateElement.remove());
      }

      if (clickTimeoutId.current) {
        clearTimeout(clickTimeoutId.current);
      }

      clickTimeoutId.current = setTimeout(() => {
        sendRequest<TPlayer>('/player/earn', {
          method: 'POST',
          body: {
            tapCount: clickCount.current,
          },
        })
          .then(response => {
            if (response) {
              dispatch(setPlayer(response));
            }
          })
          .catch(reason => console.log(reason?.message));

        clickTimeoutId.current = null;
        clickCount.current = 0;
      }, 500);
    }
  }, [dispatch, impactOccurred, availableTaps, earnPerTap]);

  return (
    <Capital>
      <S.TapContainer>
        <S.Balance>
          <SVG name="coin" width={44} height={44} />
          <span>{balance}</span>
        </S.Balance>
        <S.TapButton
          ref={tapButtonRef}
          onTouchStart={() => setIsTaping(true)}
          onTouchEnd={onClickCallback}
          $isTaping={isTaping}
        >
          <S.Body>
            {categories.map(category => {
              const currentItem = items.find(item => item.id === mapState.get(category.id));
              if (currentItem) {
                return currentItem.layers.map(layer => (
                  <img
                    key={`${category.id}-${layer.id}`}
                    src={`https://api.avataria-nft.ru/images/item-layer/${layer.image}`}
                    style={{
                      zIndex: layer.z_index || layer.z_index === 0 ? layer.z_index : category.z_index,
                    }}
                    alt=""
                  />
                ));
              }
              return null;
            })}
          </S.Body>
          <S.ButtonBackground />
        </S.TapButton>
        <S.Animate
          ref={animateRef}
        />
        <S.TapFooter>
          <S.Energy>
            <S.TapFooterProgress $width={Math.round((availableTaps / maxTaps) * 100)} />
            <SVG name="lightning"/>
            <S.EnergyText>
              <span>{availableTaps > 0 ? Math.floor(availableTaps) : 0}</span>
              <span>{maxTaps}</span>
            </S.EnergyText>
          </S.Energy>
          <S.BoostLink to="/boost">
            <SVG name="boost"/>
          </S.BoostLink>
        </S.TapFooter>
      </S.TapContainer>
    </Capital>
  );
};

export default memo(Mining);
