import * as React from 'react';
import * as S from './Loader.styled';
import { FC, memo } from 'react';
import DotLoader from '@ui/DotLoader/DotLoader';

const Loader: FC = () => (
  <S.Loader>
    <S.Text>
      Генерируем <br/>
      ваш NFT аватар
    </S.Text>
    <DotLoader />
  </S.Loader>
);

export default memo(Loader);
