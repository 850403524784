import * as React from 'react';
import * as S from '@components/Boost/Boost.styled';
import { FC, memo, useCallback } from 'react';
import { TPlayer } from '@src/app/types';
import { checkEnoughMoney, setPlayer } from '@src/redux/slices/playerSlice';
import { getHumanBalance } from '@common/number';
import { sendRequest } from '@src/network';
import { useAppDispatch } from '@common/hooks';
import { useSelector } from 'react-redux';
import SVG from '@ui/SVG/SVG';

type TProps = {
  id: string;
  name: string;
  value: string;
  price: number;
  level: number;
}

const BoostCard: FC<TProps> = ({
  id,
  name,
  value,
  price,
  level,
}) => {
  const dispatch = useAppDispatch();
  const hasEnoughMoney = useSelector(state => checkEnoughMoney(state, price));

  const buyBoost = useCallback(() => {
    sendRequest<TPlayer>('/boost/buy', {
      method: 'POST',
      body: {
        type: id,
      },
      showToast: true,
    })
      .then(response => {
        if (response) {
          dispatch(setPlayer(response));
        }
      })
      .catch(reason => console.log(reason?.message));
  }, [dispatch, id, level, name, price]);

  return (
    <S.BoostCard>
      <S.BoostName>{name}</S.BoostName>
      <S.BoostValue>
        {value}
      </S.BoostValue>
      <S.BoostReward>
        <SVG
          name="coin"
          width={18}
          height={18}
        />
        {getHumanBalance(price)}
      </S.BoostReward>
      <S.BoostBtn
        $disabled={!hasEnoughMoney}
        onClick={() => buyBoost()}
      >
        <SVG
          name="plus"
          customColor="currentColor"
        />
      </S.BoostBtn>
    </S.BoostCard>
  );
};

export default memo(BoostCard);
