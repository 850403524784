import * as S from '@components/Navigation/Navigation.styled';
import {useHapticFeedback} from '@common/hooks';
import {useLocation} from 'react-router-dom';
import React, {FC, memo, useEffect} from 'react';
import SVG from "@ui/SVG/SVG";

const Navigation: FC = () => {
    const [impactOccurred] = useHapticFeedback();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            Telegram.WebApp.BackButton.hide();
        } else {
            Telegram.WebApp.BackButton.show();
        }
    }, [location]);

    const isActive = (path: string) => location.pathname === path;

    return (
        <S.Footer>
            <S.Link
                onClick={() => impactOccurred('soft')}
                to="/"
                $isActive={isActive('/')}
            >
                <SVG name="home" customColor="currentColor" />
            </S.Link>
            <S.Link
                onClick={() => impactOccurred('soft')}
                to="/quests"
                $isActive={isActive('/quests')}
            >
                <SVG name="target" customColor="currentColor" />
            </S.Link>
            <S.Link
                onClick={() => impactOccurred('soft')}
                to="/shop"
                $isActive={isActive('/shop')}
            >
                <SVG name="constructor" customColor="currentColor" />
            </S.Link>
            <S.Link
                onClick={() => impactOccurred('soft')}
                to="/rating"
                $isActive={isActive('/rating')}
            >
                <SVG name="rating" customColor="currentColor" />
            </S.Link>
            <S.Link
                onClick={() => impactOccurred('soft')}
                to="/profile"
                $isActive={isActive('/profile')}
            >
                <SVG name="profile" customColor="currentColor" />
            </S.Link>
        </S.Footer>
    );
};

export default memo(Navigation);
