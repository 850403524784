import styled, { css } from 'styled-components';

export const Title = styled.div`
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
`;

export const Item = styled.div`
    padding: 16px 16px 0;
    border-radius: 14px;
    margin-bottom: 16px;
    border: 1px solid #F6F6F6;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 16px 0;

    &+& {
        border-top: 1px dashed #F6F6F6;
    }
`;

export const Label = styled.div`
    font-size: 12px;
    color: #868790;
`;

export const Value = styled.div`
    font-size: 12px;
    font-weight: 600;
`;

export const BoostCard = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 44px 60px 24px;
    gap: 12px;
    padding: 16px 0;

    & + & {
        border-top: 1px dashed #F6F6F6;
    }
`;

export const BoostName = styled.div`
  font-size: 12px;
  color: #868790;
`;

export const BoostValue = styled.div`
    color: #04101E;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 4px;
    font-size: 12px;
    border-radius: 12px;
    white-space: nowrap;
    text-align: center;

    span {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
    }
`;

export const BoostReward = styled.div`
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
`;

type TBoostBtn = {
  $disabled: boolean;
}

export const BoostBtn = styled.button<TBoostBtn>`${({ $disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    background-color: #077EFF;
    border-radius: 8px;
    pointer-events: ${$disabled ? 'none' : 'auto'};
    opacity: ${$disabled ? '0.5' : '1'};
    color: #fff;
`}`;
