import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

export const Header = styled.div`
    padding: 12px 16px;
`;

export const Top = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 120px;
    gap: 16px;
    margin-bottom: 12px;
`;

export const Grid = styled.div`

`;

export const NftButton = styled(NavLink)`
    width: 100%;
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #fff;
    background: #007AFF;
    border-radius: 12px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 8px 16px 0;
    position: relative;
    z-index: 10;
`;
