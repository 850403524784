import styled, { css } from 'styled-components';

export const LevelBar = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const Avatar = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
`;

export const Content = styled.div`
    flex: 1 1 auto;
`;

export const Name = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 5px;
`;

export const Progress = styled.div`
    height: 18px;
    background: #F6F6F6;
    border-radius: 10px;
    position: relative;
    padding: 2px;
`;

type TCurrentProgress = {
  $width: number;
}

export const CurrentProgress = styled.div<TCurrentProgress>`${({$width}) => css`
    width: calc(${$width}% - 4px);
    height: calc(100% - 4px);
    border-radius: 24px;
    background-image: linear-gradient(270.03deg, #017AFF 0.02%, #5BA9FF 69.66%, #C7DFF9 100.36%);
    position: absolute;
    top: 2px;
    left: 2px;
`}`;

export const Level = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    position: relative;
  
    span:nth-child(2) {
      color: #C0C3C7;
    }
`;
