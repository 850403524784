import * as React from 'react';
import * as S from '@components/Boost/Boost.styled';
import { FC, memo, useMemo } from 'react';
import { selectEarnPerTap,
  selectEnergyLevel,
  selectEnergyRecoverLevel, selectMaxPassiveEarnTime, selectMaxTaps,
  selectPassiveEarnLevel,
  selectTapLevel, selectTapsRecoverPerSec } from '@src/redux/slices/playerSlice';
import { useSelector } from 'react-redux';
import BoostCard from '@components/Boost/BoostCard';
import Capital from '@layouts/Capital';

const Boost: FC = () => {
  const tapLevel = useSelector(selectTapLevel);
  const energyLevel = useSelector(selectEnergyLevel);
  const energyRecoverLevel = useSelector(selectEnergyRecoverLevel);
  const passiveEarnLevel = useSelector(selectPassiveEarnLevel);
  const maxPassiveEarnTime = useSelector(selectMaxPassiveEarnTime);
  const maxTaps = useSelector(selectMaxTaps);
  const earnPerTap = useSelector(selectEarnPerTap);
  const tapsRecoverPerSec = useSelector(selectTapsRecoverPerSec);

  const tapPrice = useMemo(() => 10000 * 2 ** tapLevel, [tapLevel]);
  const energyPrice = useMemo(() => 10000 * 2 ** energyLevel, [energyLevel]);
  const energyRecoverPrice = useMemo(() => 10000 * 2 ** energyRecoverLevel, [energyRecoverLevel]);
  const passiveEarnPrice = useMemo(() => 10000 * 2 ** passiveEarnLevel, [passiveEarnLevel]);

  return (
    <Capital>
      <S.Item>
        <S.Title>Текущие характеристики</S.Title>
        <S.Row>
          <S.Label>Доход за клик:</S.Label>
          <S.Value>{earnPerTap}</S.Value>
        </S.Row>
        <S.Row>
          <S.Label>Максимальная энергия:</S.Label>
          <S.Value>{maxTaps}</S.Value>
        </S.Row>
        <S.Row>
          <S.Label>Восстановление энергии:</S.Label>
          <S.Value>{tapsRecoverPerSec} в секунду</S.Value>
        </S.Row>
        <S.Row>
          <S.Label>Время оффлайн дохода:</S.Label>
          <S.Value>{maxPassiveEarnTime / 60} мин</S.Value>
        </S.Row>
      </S.Item>
      <S.Item>
        <S.Title>Улучшения</S.Title>
        <BoostCard
          id="tap_level"
          name="Доход за клик"
          value="+ 1"
          price={tapPrice}
          level={tapLevel}
        />
        <BoostCard
          id="energy_level"
          name="Максимальная энергия"
          value="+ 500"
          price={energyPrice}
          level={energyLevel}
        />
        <BoostCard
          id="energy_recover_level"
          name="Восстановление энергии"
          value="+ 0,5"
          price={energyRecoverPrice}
          level={energyRecoverLevel}
        />
        <BoostCard
          id="passive_earn_level"
          name="Время оффлайн дохода"
          value="+ 5"
          price={passiveEarnPrice}
          level={passiveEarnLevel}
        />
      </S.Item>
    </Capital>
  );
};

export default memo(Boost);
