import styled, {css} from "styled-components";

export const Avatar = styled.div`
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
`;

export const Banner = styled.div`
    padding: 20px 16px;
    border-radius: 14px;
    border: 1px dashed #04101E;
    margin-bottom: 20px;
`;

export const BannerContent = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

export const BannerReward = styled.div`
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 16px;

    svg {
        margin-top: 2px;
    }
`;
export const BannerText = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: #ADADAD;
    margin-left: auto;
`;

export const Player = styled.div`
    padding: 10px;
    border-radius: 10px;
    background-color: #2872EF;
    border: 1px solid rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

export const PlayerContent = styled.div`
    margin-left: 10px;
    margin-right: auto;
`;

export const PlayerName = styled.div`
    font-size: 14px;
`;

export const PlayerPlace = styled.div`
    font-size: 20px;
    font-weight: 700;
`;

export const TabWrapper = styled.div`
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
`;

type TTab = {
    $isActive?: boolean;
}

export const Tab = styled.button<TTab>`${({$isActive}) => css`
    font-size: 14px;
    font-weight: 600;
    padding: 12px 16px;
    border-radius: 12px;
    color: ${$isActive ? '#fff' : '#04101E'};
    background-color: ${$isActive ? '#04101E' : '#F6F6F6'};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &:disabled {
        color: #ADADAD;
    }
`}`;

export const RatingReferral = styled.div`
    padding: 16px;
    border-radius: 20px;
    border: 1px solid #F6F6F6;
    backdrop-filter: blur(10px);
    margin-bottom: 10px;
`;

export const RatingReferralTop = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const PlayerLevel = styled.div`
`;

export const RatingReferralBonus = styled.div`
    background-color: #F6F6F6;
    padding: 12px 15px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    color: #0D0E22;
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const RatingReferralBonusValue = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
`;

export const RatingReferralGift = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 34px);
    justify-content: space-between;
    margin-top: 4px;
`;

type TRatingReferralGiftValue = {
    $isActive: boolean;
}

export const RatingReferralGiftValue = styled.div<TRatingReferralGiftValue>`${({$isActive}) => css`
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 10px;
    line-height: 1;
    font-weight: 600;
    color: ${$isActive ? '#04101E' : '#ADADAD'};
`}`;

export const RatingReferralLevel = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 32px);
    justify-content: space-between;
`;

type TRatingReferralLevelValue = {
    $isActive: boolean;
    $width: number;
}

export const RatingReferralLevelValue = styled.div<TRatingReferralLevelValue>`${({$isActive, $width}) => css`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${$isActive ? '#2872EF' : '#F6F6F6'};
    position: relative;
    color: ${$isActive ? '#fff' : '#ADADAD'};

    &:after {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        z-index: 1;
        outline: 2px solid #fff;
    }

    &:not(:first-child):before {
        content: '';
        position: absolute;
        top: calc(50% - 1px);
        right: 100%;
        width: calc((${$width}px - 20px - (32px * 6)) / 5);
        height: 2px;
        background-color: ${$isActive ? '#2872EF' : '#fff'};
    }
`}`;

export const RatingPlayer = styled.div`
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const ModalContent = styled.div`
    max-height: 310px;
    overflow: auto;
    margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 54px;
    gap: 10px;
`;

export const Referral = styled.div`
    background-color: #222028;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
`;

export const ReferralHeader = styled.div`
    display: flex;
    align-items: center;
`;

export const ReferralLevel = styled.div`
    font-size: 12px;
    color: rgba(255, 255, 255, .7);
    text-align: left;
    margin-top: 6px;
`;

