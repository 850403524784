import styled, { css } from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';

export const Wrapper = styled.div`
    display: grid;
    align-items: stretch;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
`;

export const Balance = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    background: #F6F6F6;
    border-radius: 12px;
    padding: 6px;
`;

export const BalanceText = styled.div`
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    color: #04101E;
`;

export const Swap = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #04101E;
    border-radius: 10px;
    padding: 8px 12px;
`;

export const SwapText = styled.span`
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    color: #fff;
`;

export const Button = styled.button`${() => css`
  background-color: #fff;
  margin-left: auto;
  width: 24px;
  height: 24px;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius: 8px;
`}`;

export const Link = styled(BaseLink)`${() => css`
  background-color: #fff;
  margin-left: auto;
  width: 24px;
  height: 24px;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius: 8px;
`}`;

export const GemModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    color: rgba(255,255,255,0.7);
    line-height: 1;
`;

export const GemModalHeaderText = styled.div`
    font-size: 18px;
`;

export const GemModalHeaderBalance = styled.div`
    
`;

export const GemModalHeaderBalanceText = styled.div`
    font-size: 12px;
    margin-bottom: 1px;
`;

export const GemModalHeaderBalanceValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    gap: 5px;
`;

export const GemWrapper = styled.div`
    margin-bottom: 20px;
`;

export const GemItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255,255,255,.1);
    border: 1px solid rgba(255,255,255,.4);
    line-height: 1;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
`;

export const GemItemCount = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    gap: 5px;
`;

export const GemItemPrice = styled.div`
    display: flex;
    align-items: center;
    color: rgba(255,255,255,.6);
    font-size: 12px;
    gap: 5px;
`;
