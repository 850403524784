import { TSkin } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

type TSkinSliceState = {
  skins: TSkin[];
  loading: boolean;
}

export const skinSlice = createAppSlice({
  name: 'skin',
  initialState: {
    skins: [],
    loading: true,
  } as TSkinSliceState,
  reducers: create => ({
    fetchSkins: create.asyncThunk(
      async (_, { signal }) => sendRequest<TSkin[]>('/skin', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
          state.skins = [];
        },
        rejected: (state, action) => {
          console.log(action.error);
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.skins = action.payload || [];
        },
      },
    ),
  }),
  selectors: {
    selectSkins: state => state.skins,
    selectSkinLoading: state => state.loading,
  },
});

export const {
  fetchSkins,
} = skinSlice.actions;

export const {
  selectSkins,
  selectSkinLoading,
} = skinSlice.selectors;

export default skinSlice.reducer;
