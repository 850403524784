import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding-bottom: 14%;
    margin-bottom: auto;
`;

export const Dot = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #04101E;

    &:nth-child(1) {
        animation: LoaderShine 1s infinite linear alternate;
    }

    &:nth-child(2) {
        animation: LoaderShine 1s infinite .3s linear alternate;
    }

    &:nth-child(3) {
        animation: LoaderShine 1s infinite .6s linear alternate;
    }

    &:nth-child(4) {
        animation: LoaderShine 1s infinite .9s linear alternate;
    }

    @keyframes LoaderShine {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-12px);
        }
        100% {
            transform: translateY(0);
        }
    }
`;
