export type TPlayer = {
  first_name?: string;
  last_name?: string;
  username?: string;
  photo_url?: string;
  telegram_id: number;
  balance_coins: number;
  total_coins: number;
  level: number;
  available_taps: number;
  max_taps: number;
  earn_per_tap: number;
  earn_passive: number;
  earn_passive_per_sec: number;
  last_passive_earn: string;
  taps_recover_per_sec: number;
  referral_link: string;
  tap_level: number;
  energy_level: number;
  energy_recover_level: number;
  passive_earn_level: number;
  max_passive_earn_time: number;
  items: string;
}

export type TCard = {
  id: number;
  name: string;
  description: string;
  price: number;
  image: string;
  income: number;
  total_income: number;
  level: number;
  requirement?: string;
  timeout_at?: number;
  expired_at?: number;
  is_unavailable?: boolean;
  buy_at: string;
}

export type TTask = {
  id: number;
  name: string;
  description: string;
  image: string;
  type: string;
  action: string;
  action_name: string;
  status: string;
  reward: number;
}

export enum TToastType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  INFO = 'INFO',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

export type TToast = {
  id: string;
  text: string;
  type: TToastType;
  closeOnTimeout: boolean;
}

export enum ShareRisk {
  EASY = 'EASY',
  NORMAL = 'NORMAL',
  HARD = 'HARD',
}

export type TShare = {
  id: number;
  name: string;
  description: string;
  image: string;
  risk: ShareRisk;
  price: number;
  status: string;
  timeout: number;
  count: number;
  income: number;
}

export type TDeposit = {
  id: number;
  name: string;
  percent: number;
  sum: number;
  timeout_at: number;
  status: string;
}

export type TItemCategory = {
  id: number;
  name: string;
  is_required: boolean;
  z_index: number;
  image: string;
}

export type TLayer = {
  id: number;
  image: string;
  x: number;
  y: number;
  z_index: number;
}

export type TItem = {
  id: number;
  category_id: number;
  name: string;
  price: number;
  layers: TLayer[];
}

export type TSkin = {
  id: number;
  color: string;
}

export enum ButtonType {
  MAIN = 'MAIN',
  LINK = 'LINK',
  TEXT = 'TEXT',
  BORDER = 'BORDER',
}

export type TGem = {
  id: number;
  count: number;
  price: number;
  image: string;
}
