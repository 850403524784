import {GlobalStyles} from '@src/global.styled';
import {Provider} from 'react-redux';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {createRoot} from 'react-dom/client';
import {store} from './redux/store';
import {theme} from '@src/theme';
import App from './App';
import Error from '@components/Error/Error';
import Investments from '@components/Investment/Investments';
import Mining from '@components/Mining/Mining';
import Quests from '@components/Quests/Quests';
import React from 'react';
import UnavailablePlatform from '@components/UnavailablePlatform/UnavailablePlatform';
import Nft from "@components/Nft/Nft";
import Shop from "@components/Shop/Shop";
import Profile from "@components/Profile/Profile";
import Friends from "@components/Friends/Friends";
import Boost from "@components/Boost/Boost";

const unavailablePlatform = [
    // 'weba',
    'tdesktop',
    'unknown',
];

const initTelegram = async (): Promise<boolean> => {
    if (typeof window !== 'undefined' && Telegram && Telegram.WebApp) {
        Telegram.WebApp.ready();
        Telegram.WebApp.expand();
        Telegram.WebApp.disableVerticalSwipes();
        Telegram.WebApp.disableClosingConfirmation();
        Telegram.WebApp.setHeaderColor('#ffffff');
        Telegram.WebApp.requestFullscreen();
        Telegram.WebApp.lockOrientation();

        return unavailablePlatform.includes(Telegram.WebApp.platform);
    }

    return true;
};

const container = document.getElementById('root')!;
const root = createRoot(container);

(async () => {
    const [isUnavailablePlatform] = await Promise.all([
        initTelegram(),
    ]);
    const availableIds = [
        415643840,
        405271027,
        178873518,
        undefined,
    ];

    if (isUnavailablePlatform || !availableIds.includes(Telegram.WebApp.initDataUnsafe.user?.id)) {
        root.render(
            <React.StrictMode>
                <ThemeProvider theme={theme}>
                    <GlobalStyles/>
                    <UnavailablePlatform/>
                </ThemeProvider>
            </React.StrictMode>,
        );
    } else {
        const router = createBrowserRouter([
            {
                path: '/',
                element: <App/>,
                children: [
                    {
                        index: true,
                        Component: Mining,
                    },
                    {
                        path: '/shop',
                        Component: Shop,
                    },
                    {
                        path: '/nft',
                        Component: Nft,
                    },
                    {
                        path: '/quests',
                        Component: Quests,
                    },
                    {
                        path: '/rating',
                        Component: Friends,
                    },
                    {
                        path: '/profile',
                        Component: Profile,
                    },
                    {
                        path: '/income',
                        Component: Investments,
                    },
                    {
                        path: '/boost',
                        Component: Boost,
                    }
                ],
                errorElement: <Error/>,
            },
        ]);

        root.render(
            <React.StrictMode>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <GlobalStyles/>
                        <RouterProvider router={router}/>
                    </ThemeProvider>
                </Provider>
            </React.StrictMode>,
        );
    }
})();
